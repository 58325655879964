<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'

export default {
  name: 'operationLogDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {}
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail.call(this, {
        url: `/log/${id}`
      })
  },
  methods: {
    getForm1() {
      return {
        title: '操作日志详情',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '操作方式',
            type: 'text',
            cols: 8,
            key: 'method'
          },
          {
            name: '方法名',
            type: 'text',
            cols: 8,
            key: 'methodName'
          },
          {
            name: '操作系统',
            type: 'text',
            cols: 8,
            key: 'os'
          },
          {
            name: '提交数据',
            type: 'text',
            cols: 8,
            key: 'params'
          },
          {
            name: '平台',
            type: 'text',
            cols: 8,
            key: 'platform'
          },
          {
            name: '操作ip地址',
            type: 'text',
            cols: 8,
            key: 'remoteAddr'
          },
          {
            name: '远程地址',
            type: 'text',
            cols: 8,
            key: 'remoteRegion'
          },
          {
            name: '请求uri',
            type: 'text',
            cols: 8,
            key: 'requestUri'
          },
          {
            name: '方法执行时间',
            type: 'text',
            cols: 8,
            key: 'time'
          },
          {
            name: '日志标题',
            type: 'text',
            cols: 8,
            key: 'title'
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '返回',
          type: 'primary',
          onClick: () => {
            this.$router.push('/monitoring/operationLog')
          }
        }
      ]

      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.detail} data={[this.getForm1()]} />
  }
}
</script>

<style scoped></style>
